//LOCATIONS PAGE

import React from "react"
import { graphql, Link } from 'gatsby'
import Wrapper from "../components/layout/wrapper"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Style from "./locations.module.scss"

const LocationsPage = ({ data }) => {

    return (
        <Layout>
        <SEO title="Student Living Locations" />
        <section className="page-hero">
          <Wrapper>
            <h1>Which city will you study in?</h1>
          </Wrapper>
        </section>
        <Wrapper>
            
            <section className={Style.locationSection}>
              <ul className={Style.locationListGroup}>
                {data.allPrismicLocation.edges.map(c => (
                  <li key={c.node.uid} className={Style.locationListItem}>
                      <Link className={Style.locationLink} to={`locations/${c.node.uid}`}>
                      <div className={Style.locationInner}>
                        <h2>{c.node.data.location}</h2>
                        <div className={Style.locationIcon}>
                          <svg class="terms__arrow" width="36px" height="11px" viewBox="0 0 36 11"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><path d="M32.3685904,4.8125 L26.0322131,1.24828777 L25.5964254,1.00315715 L26.0866866,0.131581612 L26.5224744,0.376712231 L34.5224744,4.87671223 L35.2972082,5.3125 L34.5224744,5.74828777 L26.5224744,10.2482878 L26.0866866,10.4934184 L25.5964254,9.62184285 L26.0322131,9.37671223 L32.3685904,5.8125 L26.2773438,5.8125 L0.77734375,5.8125 L0.27734375,5.8125 L0.27734375,4.8125 L0.77734375,4.8125 L26.2773438,4.8125 L32.3685904,4.8125 Z" fill="currentColor" fill-rule="nonzero"></path></g></svg>
                        </div>
                      </div>
                      </Link>
                      <hr />
                  </li>
                ))}
              </ul>              
            </section>
        </Wrapper>
      </Layout>
    )
}

export default LocationsPage

export const query = graphql`
query LocationsPage {
  allPrismicLocation {
    edges {
      node {
        data {
          location
        }
        uid
      }
    }
  }
  }
`